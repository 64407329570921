@import "../../assets/styles/main.scss";

.duration-box {
    border: 2px solid $primary-gray;
    height: 62px;
    width: max-content;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .seperation-line {
        border: 1px solid $primary-gray;
        // margin: 5px 0px;
        height: 48px;
        margin: auto 0px;
    }

    .selection {
        cursor: pointer;
        height: 50px;
        // For auto width
        // width: max-content;
        // padding: 0px 10px;

        // For fixed width
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px;
        border-radius: 5px;
        background-color: $white;
        transition: background-color 0.3s ease;
    }

    .selected {
        border-radius: 5px;
        background-color: $primary-red;
        color: $white;
        transition: background-color 0.3s ease;
    }
}