@mixin device($size) {
  @if $size == tablet {
    @media only screen and (min-width: 481px) and (max-width: 768px) {
      @content;
    }
  }

  @if $size == desktop {
    @media only screen and (min-width: 769px) {
      @content;
    }
  }
}
