@import "../../../../../../assets/styles/main.scss";

.tabContent {
    .rating-container {
        width: 50%;
        margin-bottom: 20px;

        .avg-rating-container {
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            .avg-rating {
                font-size: 30px;
                font-weight: 700;
                margin-right: 15px;
            }

            .star-rating {
                .star-ratings {
                    font-size: 20px;
                }
            }
        }

        .single-ratings {
            .single-rating-line {
                .number {
                    font-size: 16px;
                    // font-weight: 600;
                    margin-right: 15px;
                }
            }
        }
    }

    .review-container {
        .review {
            .review-rating {
                font-size: 18px;
            }
        }
    }
}