@import "../../assets/styles/main.scss";

.tabContent {
    .add-field {
        .add-field-form {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 15px;

            .ant-form-item {
                margin-bottom: 0px;
            }

            .input-field {
                // width: 500px;
                height: 50px;
            }

            .add-btn {
                height: 50px;
                width: 80px;
            }
        }
    }

    .new-items {
        margin-top: 10px;
        display: flex;

        .tag {
            .tags-component {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-width: 100px;
                border-radius: 20px;
                font-size: 15px;
                height: 40px;
                padding-left: 15px;
                gap: 10px;
            }
        }
    }

    .btn-container {
        margin: 10px 0px;
        display: flex;
        justify-content: end;

        .SaveBtn {
            height: 40px;
        }
    }

    .display {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 15px;

        .product-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            height: 50px;
            border: 1px solid $primary-gray;
            border-radius: 3px;

            .item-edit-form {
                height: 100%;
                display: grid;
                grid-template-columns: 4fr 1fr 1fr;

                .edit-input {
                    margin-right: 5px;
                }
            }

            // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            .op-btns {
                // border: 1px solid red;
                height: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;

                .remove-btns {
                    color: #569dfa;
                    font-size: 20px;
                    // padding: 2px 0px;
                    // z-index: 1000;
                    top: 0px;
                }
            }
        }
    }
}