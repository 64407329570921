.microsite-header-lastItem{
    margin: 15px;
}

.with-microsite{
    width: 100%;
    display: flex;
    flex-direction: column;
    // gap: 20px;
    padding: 5px;


        .microsite-header-title{
            font-size: 18px;
            font-weight: 600;

        }
    }
    .with-microsite-header{
        margin-top: 10px;
        display: block;
        // flex-direction: row !important;
        // justify-content: space-between;
        // align-items: center;
    }

// .ant-form-item-control-input-content{
//     display: flex;
//     align-items: center;
// }



.choose-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
}

// .ant-form-item-row{
//     display: flex;
//     align-items: center;
// }

.centered-label .ant-form-item-label {
    display: flex;
    align-items: center;
}

.with-microsite .custom-btn {
    background-color: #5AA2FB;
    border-radius: 5px; 
    padding: 20px; 
    color: white;
    display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-right: 20px;
    
    .text-btn {
        color: white;
    }

    &:hover {
        background-color: darken(#5AA2FB, 10%);
        color: white;
    }
}


.footer-container {
    justify-content: center;
    // padding-top: 1%;
    margin-bottom: -2%;
    display: flex;

    .footer-content {
      display: flex;
      align-items: center;
      // justify-items: center;
      // width: 60%;
      margin-left: auto;
      margin-right: auto;

      span {
        right: -13%;
        position: relative;
        font-size: 60%;
      }

      .footer-logo {
        justify-content: center;
        display: flex;
      }

      img {
        height: auto;
        width: 100px;
      }
    }
  }


  .float-container {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 1001;

    .float-group {
      background-color: white;
      border-radius: 50px;
      border: 1px solid #0000001a;
      box-shadow: 10px 10px 13px #0000001a;
      padding: 10px;
      padding-right: 13px;
      gap: 20px;
      align-items: center;
    }

  }