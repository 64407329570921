@import "../../../assets/styles/main.scss";

.greenBtn {
  background-color: $primary-green !important;
  border: 1px solid $primary-green !important;
  border-radius: 5px !important;
  color: $white !important;
  cursor: pointer;
}

.helpOrg {
  padding-left: 0px !important;
  display: flex;
  justify-content: flex-start;
  margin-left: -10px;
  // margin-top: 5px; 
}

// .back-btn{
//   // padding-left: 20px;
//   // background-color: red;
//   margin-left: 20px;
//   position: relative;
//   top: 35px;
//   z-index: 1;
// }



.back-btn {
  padding-left: 20px;
}

// ::file-selector-button {
//     display: none;
// }

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px !important;
}

.rc-virtual-list-holder {
  overflow: auto !important;
}

.rc-virtual-list-scrollbar-thumb {
  display: none;
}

.ant-input {
  border-radius: 5px !important;
}

.flexrow {
  display: flex;
  justify-content: flex-end;
}

.tabContainer {
  padding-left: 20px;
  padding-right: 20px;
  // margin-top: 15vh;
  min-height: 80vh;

  .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top>.ant-tabs-nav:before,
  .ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top>div>.ant-tabs-nav:before {
    bottom: unset;
    border: none;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 4px solid $primary-red !important;
  }

  .ant-tabs-tab:hover {
    color: gray;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
    // letter-spacing: 1px;
    // font-size: 20px;
  }

  .ant-tabs-content-holder {
    background-color: $white;
    padding: 20px;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    display: unset;
  }

  .ant-tabs-tab-btn {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 15px;
  }

  .ant-tabs-bottom>.ant-tabs-nav,
  .ant-tabs-bottom>div>.ant-tabs-nav,
  .ant-tabs-top>.ant-tabs-nav,
  .ant-tabs-top>div>.ant-tabs-nav {
    margin: 0;
  }

  .buttons {
    position: absolute;
    right: 8px;
    margin: 0px 12px;
    color: $white;
    border-radius: 5px;
    z-index: 999;
    display: flex;
  }

  .nextBtn span {
    padding: 0 20px;
  }

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .inputBody {
    display: flex;
    align-items: center;
    margin: 10px;
    justify-content: right;

    label {
      width: 15%;
    }

    input {
      width: 40%;
    }

    .ant-picker {
      width: 40%;
      border-radius: 5px !important;
    }

    .ant-input-affix-wrapper {
      width: 40%;
      border-radius: 5px !important;
    }

    .ant-select {
      width: 40% !important;
    }

    .ant-input {
      width: 40% !important;
    }

    .addAddressBtn {
      display: flex;
      justify-content: end;

      // button {
      //   background-color: $primary-green;
      //   border: 1px solid $primary-green;
      //   color: $white;
      //   border-radius: 5px;
      // }
    }

    .wid28 {
      width: 28% !important;
    }
  }
}

.ant-form-horizontal .ant-form-item-label {
  text-align: left !important;
}

.address-form {
  .ant-form-item-label {
    label {
      height: 40px;

      ::after {
        content: none;
      }
    }
  }
}

.modalBody {
  padding: 20px;
  margin: 20px;
  text-align: center;

  Button {
    width: 100%;
    margin: 20px 10px;
  }

  h3 {
    margin-bottom: 15px;
    font-weight: bold;
  }
}

.ant-col-10 {
  max-width: 100% !important;
}

.footerlogo {
  text-align: center;
}