@import "../../assets/styles/main.scss";

.analytics-tab {
    .microsite-overall-views-graph {
        width: 99%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin: 10px;
    }

    .first-child {
        margin-bottom: 10px;
    }

    .filter-box {
        border: 1px solid rgba(197, 197, 197, 1);
        border-radius: 5px;
        box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.15);
        padding: 15px;
        margin-bottom: 15px;

        .filters-container-analytics {
            // display: flex;
            // align-items: center;
            // justify-content: space-between;
            // margin-bottom: 10px;

            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin-bottom: 10px;

            .ages {
                height: 40px;

                .age-cards {
                    display: flex;
                    gap: 10px;
                    height: max-content;

                    margin-right: 15px;

                    .card {
                        padding: 2px 10px;
                        width: max-content;
                        height: max-content;
                        font-weight: bolder !important;
                        margin: 0px;
                        background: #7E97B8;
                        border-radius: 5px;
                        color: #ffffff;
                        border: none;
                        cursor: pointer;
                        transition: background 0.3s ease;
                    }

                    .selected-age {
                        color: $white;
                        font-weight: bolder !important;
                        background-color: $primary-red;
                        transition: background 0.3s ease;
                    }
                }
            }

            .prof-cards {
                display: flex;
                justify-content: end;

                .select-formitem {
                    display: flex;
                    align-content: center;
                    margin-bottom: 0px;

                    .ant-form-item-label {
                        display: flex;
                        align-items: center;
                    }

                    label {
                        font-size: 16px;
                        font-weight: 700;
                    }
                }

                .customSelect {
                    width: 300px;
                }

                .delete-btn {
                    color: #569dfa;
                    border-radius: 50%;
                    font-size: 18px;
                    padding: 5px 3px;
                    z-index: 1000;
                    top: -4px;
                    margin-left: 5px;
                }
            }

        }

        .add-filter-btns {
            // display: flex;
            // justify-content: space-between;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            // gap: 10px;

            .align-right-class {
                display: flex;
                justify-content: end;
            }

            .term-select {
                margin-bottom: 0px;

                .ant-form-item-label {
                    display: flex;
                    align-items: center;
                }

                label {
                    font-size: 16px;
                    font-weight: 700;
                }
            }

            .customSelect {
                width: 300px;
                // margin-right: 10px;
            }
        }
    }

    .overall-views-graph {
        width: 100%;
        // height: 470px;
        height: auto;
        border: 1px solid $primary-gray;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        // justify-content: center;
        // align-items: center;

        .microsite-data {
            width: 100%;
            height: 100%;
            padding: 15px 20px;

            .all-time-count {
                width: 95%;
                margin-bottom: 20px;
            }

            .selection-container {
                margin: 0px 0px 10px 0px;
                display: flex;

                .user-count {
                    margin-left: 30px;
                    display: flex;

                    .total-count {
                        // border: 1px solid red;
                        height: 100%;
                        margin: 0px !important;
                        font-size: 37px;
                        font-weight: 800;
                        color: $primary-red;
                    }

                    .count-label {
                        :first-child {
                            margin-bottom: 0px;
                        }

                        :last-child {
                            margin-top: 0px;
                        }
                    }
                }
            }

            .microsite-info {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 40px;

                .microsite-name,
                .microsite-count {
                    margin: 15px 0px;
                    font-size: 18px;
                    font-weight: 500;
                }

                .view-microsite-anaytics-btn {
                    cursor: pointer;

                    &:hover {
                        background-color: $primary-red !important;
                    }
                }
            }

            .users-by-map {
                width: 100%;

                .graphical-heading {
                    display: flex;
                    justify-content: center;
                }

                .charts {
                    display: flex;
                    gap: 10px;

                    .geoChart {
                        height: auto;
                        border-radius: 15px;
                        width: 50%;
                        padding: 15px;
                        border: 1px solid rgba(197, 197, 197, 1);
                        border-radius: 15px;
                        box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.15);

                        .gchart {
                            width: 100%;
                            transform: scale(1);
                        }
                    }

                    .local-chart {
                        width: 100%;

                        .map-component {
                            height: 500px;
                            width: 100%;
                        }

                        .legend-swatches {
                            display: flex;
                            gap: 10px;
                            justify-content: center;
                            margin-top: 20px;

                            .swatch {
                                height: 40px;
                                width: 100px;
                            }
                        }

                        .gradient-legend {
                            margin-top: 20px;
                            height: 20px;
                            width: 100%;
                            background: rgb(30, 158, 243);
                            background: linear-gradient(100deg, rgba(30, 158, 243, 1) 0%, rgba(12, 139, 225, 1) 16%, rgba(10, 118, 190, 1) 31%, rgba(8, 97, 155, 1) 48%, rgba(7, 75, 121, 1) 65%, rgba(5, 54, 86, 1) 83%, rgba(3, 32, 52, 1) 100%);
                            fill-opacity: 0.7;
                        }

                        .text-legend {
                            display: flex;
                            justify-content: space-between;

                            p {
                                font-size: 15px;
                                font-weight: 500;
                            }
                        }
                    }

                    .barChart {
                        width: 50%;
                        padding: 15px;
                        border: 1px solid rgba(197, 197, 197, 1);
                        border-radius: 15px;
                        box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.15);

                        .gchart {
                            width: 100%;
                            transform: scale(1);
                        }
                    }
                }

            }
        }

        .graph-wrapper {
            width: 95%;
            margin-bottom: 15px;
        }
    }

}