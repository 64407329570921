.reset-password-container {
    .organizationForm .ant-row {
        display: flex;
        align-items: center;
    }
    .ant-input {
        height: 32px;
        border: 1px solid #c4c3c3;
    }
    .ant-input-affix-wrapper {
        border-radius: 5px !important;
    }
    .submitBtn {
        border: 1px solid !important;
        border-radius: 5px !important;
        color: white !important;
        cursor: pointer;
        float: right;
    }
}
