$black: #000000;
$white: #ffffff;

$grey-1: #f1f1f1;
$grey-2: #f2f2f2;
$grey-3: #f4f4f4;
$grey-4: #f6f6f6;
$grey-5: #f9f9f9;
$grey-6: #f9fbfa;

// $primary-green: #4bcd92;
$primary-orange: #ed841a;
$primary-gray: #8d97a1;
$primary-black: #343a43;
$primary-red: #CC0E2D;
$primary-blue: #5AA2FB;
$primary-green: #59A638;

$secondary-grey: #d2d5df;
$secondary-yellow: #ffc200;
$secondary-teal: #00a8b0;
$secondary-green: #d0d59a;
$secondary-blue: #4D93F9;
$secondary-purple: #560cab;
$secondary-slate: #d5d6e3;
$secondary-slate-light: #e9ebf4;
$secondary-orange: #e09b7b;

$button-grey: #dcdcdc;

$body-background: #f5f5f5;

$table-head-bg: #7E97B8;
$border-grey: #C5C5C5;

$background: #569DFA;

$alphabet-btn-normal: #7E97B8;
$alphabet-btn-selected: #3067AE;