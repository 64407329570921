@import "../../assets/styles/main.scss";

.header {
  display: flex;

  .settings-header {
    text-align: start;
    margin-left: 20px;
  }
}

.ms-settings {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 20px;
  border: 1px solid #ccc;
  margin: 10px 0;
  border-radius: 6px;
  box-shadow: 0 0 10px rgb(241, 241, 241);
  // height: 300px;
  // overflow: scroll;
  // overflow-x: hidden;

  .ant-switch {
    background-color: white;
    // border: 1px solid rgb(12, 107, 180);
    border: 1px solid $primary-red;

    .ant-switch-handle::before {
      background: rgb(182, 182, 182) !important;
      // border: 1px solid rgb(12, 107, 180);
      border: 1px solid $primary-red;
    }
  }

  .ant-switch-checked {
    background-color: white;
    // border: 1px solid rgb(12, 107, 180);
    border: 1px solid $primary-red;

    .ant-switch-handle::before {
      background: white !important;
      // border: 1px solid rgb(12, 107, 180);
      border: 1px solid $primary-red;
    }
  }

  .ant-switch-handle {
    top: 1px;
  }

  .ms-settings-row {
    display: flex;
    width: 100%;

    .ms-settings-switch {
      margin-left: auto;
    }
  }

  .ms-settings-label {
    font-weight: 700;
  }
}

.all-switch {
  display: flex;
  justify-content: end;
  padding: 5px 20px;
  // font-size: medium;
  font-weight: 700;
}

.ms-settings-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .ms-settings-footer-sub {
    display: flex;
  }
}

.btn-secondary:hover,
.btn-primary-alt:hover {
  border: 1px solid transparent;
}