@import "../../assets/styles/main.scss";

.reset {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.forgotPwdButtons {
    display: flex;
    justify-content: end;
}