@import "../../assets/styles/main.scss";

.tabContainer {
    .analytics-tab {
        .sub-heading {
            font-size: 16px;
            margin: 10px 0px;
            font-weight: 600;
        }

        .microsite-analytics {
            .microsite-analytics-header {
                font-size: 18px;
                font-weight: 600;
                display: flex;
                gap: 10px;
                align-items: center;

                img {
                    cursor: pointer;
                }
            }

            .filter-box {
                .filters-heading {
                    font-size: 16px;
                    font-weight: 600;
                    margin-left: 0px;
                    margin-top: 0px;
                }
            }
        }

        .overall-views-graph {
            .microsite-data {
                .modal-sub-heading {
                    font-size: 16px;
                    margin-left: -5px;
                    margin-top: -5px;
                }
            }
        }
    }
}