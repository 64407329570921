@import "../../assets/styles/adaptive.scss";
@import "../../assets/styles/main.scss";

.tabContainer {
    .add-microsite-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0px 10px 0px;
    }

    .table-container {
        // border: 2px solid red;   

        .ant-table-thead {
            .ant-table-cell {
                background: $table-head-bg !important;
                color: white;
                font-weight: 600;
            }

            tr {
                th {
                    &:first-child {
                        border-start-start-radius: 7px !important;
                    }

                    &:last-child {
                        border-start-end-radius: 7px !important;
                    }
                }
            }
        }

        .microsite-info {
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 7fr;

            .table-microsite-logo {
                display: flex;
                justify-content: start;

                img {
                    height: 40px;
                }
            }

            .table-microsite-name {
                // margin-left: 10px;
                font-size: 17px;
                font-weight: 600;
            }
        }

        .microsite-header-last {
            // border: 2px solid red;

            .microsite-header-last-sub {
                display: flex;
                align-items: center;
                justify-content: space-around;
                gap: 20px;

                .microsite-header-last-sub-sec {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;

                    .manage-table-icons {
                        height: 30px;
                    }
                }
            }
        }
    }
}