@font-face {
  // font-family: "Muli";
  // src: url(../fonts/Muli-Regular.ttf) format("truetype");
  font-family: 'Airbnb';
  src: url(../fonts/AirbnbCereal_W.otf) format("truetype");
  font-family: 'Airbnb-light';
  src: url(../fonts/AirbnbCereal_W_Bk.otf) format("truetype");
}

@import "colors";

body {
  // font-family: "Muli" !important;
  font-family: 'Airbnb-light' !important;
  // background-color: $body-background !important;
  background-color: white;
  margin: 0;

  a {
    text-decoration: none;
    color: initial;
  }

  p {
    margin: 6px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}

.mt10 {
  margin-top: 10px;
}

.greenBtn {
  // background-color: $primary-green !important;
  // border: 1px solid $primary-green !important;
  background-color: $primary-red !important;
  border: 1px solid $primary-red !important;
  font-weight: 600 !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  border-radius: 5px !important;
  color: $white !important;
  cursor: pointer;
}

.disbledBtn {
  background-color: $secondary-grey !important;
  border: 1px solid $secondary-grey !important;
  font-weight: 600 !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  border-radius: 5px !important;
  color: $white !important;
  cursor: not-allowed;
}

.signup-form-next-btn {
  &:hover {
    background-color: $primary-red !important;
    border: 1px solid $primary-red !important;
    font-weight: 600 !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    border-radius: 5px !important;
    color: $white !important;
    cursor: pointer;
  }
}

.editbtn {
  background-color: $white !important;
  border: 2px solid $primary-red !important;
  border-radius: 5px !important;
  height: 40px !important;
  color: black !important;
  font-weight: 600 !important;
}

.page-header {
  .backbtn-container {
    display: flex;

    img {
      margin-right: 10px;
      cursor: pointer;
    }

    p {
      font-weight: 600;
      font-size: 20px;
    }
  }
}

.confirmation-modal {
  width: max-content;

  .message {
    // border: 2px solid red;
    // margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0px 10px 0px;
  }

  .warning {
    color: $primary-red;
  }

  .modalBtns {
    // border: 2px solid red;
    display: flex;
    justify-content: end;
    padding-top: 10px;
    gap: 15px;

    .cancel-btn {
      background-color: $white;
      border: 2px solid $primary-red;
      border-radius: 5px;
      height: 40px;
      color: black;
      font-weight: 600;
    }

    .confirm-btn {
      background-color: $primary-red;
      border-radius: 5px;
      height: 40px;
      display: flex;
      align-items: center;
      color: $white;
      font-weight: 600;
    }

  }
}

@media only screen and (max-width: 420px) {
  .tabContent {
    width: 100%;
    min-width: 90%;
  }

  .tabHeadContainer {
    padding: 0px;

    .rowBody {
      flex-direction: column-reverse;
      gap: 20px;

      .tabContent {
        width: 100%;
        min-width: 90%;
      }

      .corousel-col {
        width: 100%;
        margin: 0;
        min-width: 100%;
        margin-top: 20px;
      }
    }

    .ant-tabs-content-holder {
      padding: 5px !important;
    }

    .inputBody {
      // width: 100%;
      // min-width: 100%;

      // div {
      //   display: flex;
      //   gap: 10px;
      //   justify-content: center;
      //   align-items: center;
      // }
    }

    .carousel-main-container {
      width: 250px;
      margin-bottom: 20px;
    }
  }

  .inputBody {
    // width: 100%;
    // min-width: 100%;
    // flex-direction: column;

    // div {
    //   display: flex;
    //   gap: 10px;
    //   justify-content: center;
    //   align-items: center;
    // }
  }

  .ms-edit-main {
    flex-direction: column;

    .ms-edit-left {
      flex-direction: column;
      gap: 10px;
    }

    .ms-edit-right {
      margin: 0;
    }
  }

  .microsite-header {
    display: flex;

    .microsite-header-first {
      flex-direction: column;
      gap: 10px;
    }

    flex-direction: column;

    .microsite-header-last {
      margin: 20px 0 10px 0;
    }
  }

  .ant-popover-placement-bottom,
  .ant-popover-placement-bottomLeft,
  .ant-popover-placement-bottomRight {
    width: 100%;
    overflow-wrap: break-word;
  }

  .changePwdContainer .changePwdBody .pwdUpdateBtn {
    width: 100%;
  }

  .login-bg .login-main-container .login-right-panel .card {
    width: 100%;
    margin: 0;
    padding: 20px;
    height: 100%;
  }

  .login-bg .login-main-container .login-right-panel {
    margin: 0;
  }

  .login-bg .login-main-container {
    padding: 0%;
  }

  .login-bg .login-main-container .login-right-panel .card-login .login-form-container {
    margin-top: 10px;
  }

  .changePwdBody {
    .gutter-row {
      min-width: 100%;
    }

    .signUpBtns {
      flex-direction: column;
      margin: 0;

      div {
        text-align: center;
      }
    }
  }

  .headerBannerCol {
    div:last-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .headerBannerCol {
    div:first-child {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .microsite-edit-header {
    padding: 10px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 767px) {}

@media only screen and (min-width: 768px) {}