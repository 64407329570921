@import "../../assets/styles/adaptive.scss";
@import "../../assets/styles/main.scss";

.custom {
    width: 95%;
    // margin-top: 15vh;
    margin-right: auto;
    margin-left: auto;
}

.changePwdHeading {
    color: black !important;
    border-bottom: 5px solid $primary-green;
    width: 140px;
    margin-bottom: 15px;
}

.customBody {
    padding: 10px;
}

.change-pwd-form-container {
    // width: 70%;

    .sendBtn {
        background-color: $primary-blue;
        color: $white !important;
        border-radius: 5px;
        letter-spacing: 0.5px;

        &:hover {
            background-color: $secondary-blue;
        }
    }
}

.customBody .ant-input {
    border: 1px solid #c4c3c3;
    border-radius: 5px !important;
    height: 40px;
}
.customBody .ant-input-affix-wrapper {
    border-radius: 5px !important;
}
