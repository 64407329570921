@import "../../assets/styles/adaptive.scss";
@import "../../assets/styles/main.scss";

.custom {
  width: 95%;
  // margin-top: 15vh;
  margin-right: auto;
  margin-left: auto;
}

.connectsu-line-help {
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-top: 20px;
}

.title-help {
  font-weight: bold;
  margin-left: 5px;
  margin-bottom: 10px;
  font-size: 15px;
}

.custom-card {
  padding: 0;
}

.tabContainer {
  padding: 10px 20px 10px 20px;
  min-height: 80vh;

  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > .ant-tabs-nav:before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav:before {
    bottom: unset;
    border: none;
  }

  .ant-tabs-tab {
    padding: 5px 0px;
  }

  .ant-tabs-tab:hover {
    color: gray;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
    // letter-spacing: 1px;
    // font-size: 20px;
  }

  .ant-avatar {
    img {
      object-fit: unset !important;
    }
  }

  .ant-tabs-content-holder {
    background-color: $white;
    width: 100%;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #bababa;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    min-height: 440px;
    margin: 10px;
    margin-left: 0px;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    display: unset;
  }

  .ant-tabs-tab-btn {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 15px;
  }

  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav,
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav {
    margin: 0;
  }

  // .buttons {
  //   position: absolute;
  //   right: 8px;
  //   margin: 12px;
  //   color: $white;
  //   border-radius: 5px;
  //   z-index: 999;
  //   // justify-content: end;

  //   .saveBtn {
  //     // padding: 2%;
  //     width: 75px;
  //   }
  // }

  .ant-form .ant-form-horizontal {
    padding-top: 5%;
    padding-left: 3%;
  }
}

.help-cardContent {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 40% !important;
  // height: 10vh;
  // background-color: #d7d7d7;
  box-shadow: 0px 0px 3px #0000001a;

  .media {
    height: 100%;
    width: 100%;
    display: flex;
    margin-right: 10px;
    // border: 2px solid green;
    align-items: center;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .youtube {
    //   height: 80%;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   // width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 7em;

    .ytBtnOverlay {
      height: 70px;
      width: 70px;
      object-fit: contain;
      position: absolute;

      // top: 50%;
      // left: 15%;
      // transform: translate(-50%, -50%);
      // padding: 10px;
    }
  }
}

.infoClass {
  padding: 10px;
  max-width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card-title {
    font-size: 17px;
    font-weight: 600;
  }

  .btn-km {
    width: fit-content;
  }
}

.ant-card-body {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
}

.help-org-card-container {
  margin: 10px;
  margin-left: 0px;
}

.help-card-item {
  height: 10em;
  // margin-left: 5px;
  box-shadow: 0px 0px 3px #0000001a;
  border: 1px solid #bababa;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.custom-card {
  border-radius: 5px;
  overflow: hidden;
  height: 150px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.card-content {
  display: flex;
  // justify-content: space-between;
  // align-items: flex-start;
}

.card-text-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card-description {
  width: 100%;
  margin-right: 5px;
}

.video-container {
  flex-basis: 200px;
  height: 120px;
  overflow: hidden;
}

.footer {
  justify-content: center;
  // padding-top: 15%;
  // padding-bottom: 4%;
  display: flex;

  .footer-content {
    display: flex;
    align-items: center;
    // justify-items: center;
    // width: 60%;
    margin-left: auto;
    margin-right: auto;

    span {
      right: -13%;
      position: relative;
      font-size: 60%;
    }

    .footer-logo {
      justify-content: center;
      display: flex;
    }

    img {
      height: auto;
      max-width: 100px;
    }
  }
}
.sub-header {
  margin-top: 15px;
  text-align: center;
  font-size: 20px;
}
.searchbar-containers {
  // width: 330px;
  height: 40px;
  margin-right: 10px;
  // margin-right: 10px;
  // padding-right: 10px;
  // border-right: 1px solid $border-lines;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-input {
    // margin-top: 3px;
    // padding-left: 5px;
    height: 32px;
  }

  .searchbar {
    width: 100%;
    height: 100%;
    margin-right: 10px;
  }
}
