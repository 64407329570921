@media (min-width:768px) {
  .custom-slider-container {
    padding: 0px;
    // width: 400px;
    width: 90%;
    height: 280px;
    padding-top: 10%;

    .slick-slider {
      height: 100%;

      .slick-prev:before,
      .slick-next:before {
        color: gray;
      }

      .slick-list {
        height: 100%;

        .slick-track {
          height: 100%;
        }
      }
    }

    .slide {
      // max-height: 25vh;

      iframe {
        height: 200px;
      }

      .img-slide {
        img {
          object-fit: cover;
          // max-width: 50%;
          height: 200px;
          width: 100%;
        }
      }



    }

    .slider-img {
      width: auto;
      max-width: 100%;
      height: auto;
      background-color: antiquewhite;
    }

    .slick-center {
      transform: scale(1.08);
      // align-items: center;
      // padding: 20px 0;
      // padding-bottom: 20px;
      // width: auto;
      // height: 100%;

    }
  }

  .slick-slide:not(.slick-active) {
    margin: 20px 0;
    overflow: hidden;
    // transform: scale(0.95);

    // img {
    //   height: 220px;
    // }
  }
}



.slick-active {
  align-items: center;
  padding: 20px 0;
  width: 220px;
  // height: 100%;

}

.slide:not(.slick-active) {
  cursor: pointer;
}

@media (min-width:1024px) {
  .custom-slider-container {
    // height: 45%;
    height: 250px;
    width: 86%;

    .slide {
      // max-height: 25vh;

      iframe {
        height: 160px;
        max-width: 100%;
      }

      .img-slide {
        img {
          object-fit: cover;
          max-width: 100%;
          height: 160px;
          width: 100%;
        }
      }
    }
  }
}

@media (min-width:1440px) {
  .custom-slider-container {
    // height: 45%;
    height: 300px;
    width: 86%;
    max-width: 450px;


    .slide {
      // max-height: 25vh;

      iframe {
        height: 200px;
        max-width: 100%;
      }

      .img-slide {
        img {
          object-fit: cover;
          max-width: 100%;
          height: 200px;
          width: 100%;
        }
      }
    }
  }
}

@media (min-width:2100px) {
  .custom-slider-container {
    // height: 65%;
    height: 500px;
    width: 85%;
    max-width: 600px;

    .slide {
      // max-height: 25vh;

      iframe {
        height: 400px;
      }

      .img-slide {
        img {
          object-fit: cover;
          // max-width: 50%;
          height: 400px;
          width: 100%;
        }
      }
    }
  }
}