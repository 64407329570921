@import "../../assets/styles/adaptive.scss";
@import "../../assets/styles/main.scss";

.login-bg {
  width: 100%;
  height: 100%;
  // background:
  //   linear-gradient(rgba(0, 0, 0, 0.5),
  //     rgba(0, 0, 0, 0.5));  


  // background-image: linear-gradient(45deg,
  // rgba(103, 121, 148, 0.685),
  // rgba(2, 102, 202, 0.601)), url("../../assets/images/backgroundImg2.jpeg");

  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  @include device(desktop) {
    display: flex;
  }

  .share-btn {
    // border: 2px solid red;
    // height: fit-content;
    position: absolute;
    right: 25px;
    top: 20px;
    display: flex;
    transform: scale(1.6);
    color: white;

    :hover {
      cursor: pointer;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .login-main-container {
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding-right: 100px;
    justify-content: center;
    align-items: center;

    @include device(desktop) {
      flex-direction: row;
    }

    .login-left-panel {
      margin-left: 100px;
      padding-right: 250px;

      .header {
        font-size: 45px;
        font-weight: bolder;
        // color: rgb(0, 0, 0);
        color: white;

      }

      .content {
        font-size: 21px;
        // font-weight: 600;
        // color: rgb(0, 0, 0);
        color: white;

      }

      p,
      h1 {
        padding: 0 20px;
        text-align: center !important;
        color: white;
      }


    }

    .login-right-panel {
      margin: auto 0px auto auto;

      .card-login {
        width: 300px;
        background-color: white;
        box-shadow: #00000017 0 3px 6px;
        border-radius: 40px;
        // height: 85vh;
        padding: 30px 20px;

        .logo {
          // margin-top: 30px;  
          // height: 30px;
          margin-left: auto;
          margin-right: auto;
          width: 50%;
          display: flex;
          justify-content: center;

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }

        .login-form-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          // margin-top: 60px;

          .form-container {
            width: 100%;
            margin-top: 20px;
          }

          .form-label {
            font-weight: 700;
          }

          .login-button {
            margin-top: 10px;
            height: 40px;
            cursor: pointer !important;
          }

          .labeled-input {
            margin-top: 10px;
            width: 80%;

            .input-label {
              font-size: 14px;
            }

            input {
              width: 100%;
              outline: none;
              border: 1px solid #e0e0e0;
              box-shadow: #00000017 0 3px 6px;
              padding: 5px;
              border-radius: 5px;
            }
          }
        }

        .forgot-password {
          text-align: end;
          color: red;
          cursor: pointer;
          font-weight: 600;
          margin-top: 1px;
        }

        .sign-up {
          text-align: start;
          color: blue;
          cursor: pointer;
          font-weight: 600;
        }

        .links {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;
        }
      }
    }
  }
}

.Reset-headerLogo {
  height: 8vh;
  padding: 1vh 0 1vh 0;
}

.forgotPwdContainer {
  padding: 20px;

  .forgotPwdHeading {
    border-bottom: 5px solid $primary-green;
    width: 130px;
    margin-bottom: 15px;
  }

  .forgotPwdBody {
    box-shadow: rgba(177, 177, 177, 0.1) 0px 5px 5px;
    padding: 20px;
    border-radius: 10px;
    min-height: 200px;
    border: 1px solid $secondary-grey;
    padding: 50px 20px;

    // .ant-col-10 {
    //   max-width: 100%;
    // }
    .forgotPwdBtns {
      display: flex;
      align-items: center;
      justify-content: right;

      button {
        margin-left: 10px;
      }

      .cancelBtn {
        background-image: linear-gradient(#e2dddd, #a5a5a5);
        border-radius: 5px;
      }

      .sendBtn {
        background-color: $secondary-blue;
        border-radius: 5px;
        color: $white;
        letter-spacing: 0.5px
      }
    }
  }
}

@media (min-width:360px) {

  // .headerBannerRow {
  //   display: none;
  // }

  .signup-logo {
    height: 6vh;
  }

  .signUpHeading {
    color: white;
    padding-left: 10px;
  }

  .container {
    margin-top: 0px !important;
    display: flex;
    flex-direction: row !important;
    align-items: center;
  }

  .form-item {
    margin-bottom: 3px !important;
  }

  .signUpBtns {
    margin-left: 0px;
    margin-top: 10% !important;
    justify-content: center;
  }
}

@media (min-width:768px) {

  .headerBannerRow {
    display: block;
  }

  .signup-logo {
    height: 10vh;
  }

  .form-item {
    margin-bottom: 20px !important;
  }

  .signUpBtns {
    // gap: 20px;
    margin-top: 5% !important;
  }
}

.terms-container {

  .termsHeading {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
  }

  .terms-text {
    border: 1px solid $secondary-grey;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    margin: 0px auto;
    overflow-y: scroll;
    height: 500px;
    margin-bottom: 20px;
    width: 60%;

    .terms-content {
      padding: 20px;
    }
  }

  .check-submit {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .submitbtn {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.changePwdContainer {
  background-color: white;
  border-radius: 10px;
  // margin: 20px;
  width: 98%;
  margin: 20px auto;

  .signUpHeading {
    // width: 66px;
    // padding: 20px 0;
    padding-bottom: 10px;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: white;
    background: $primary-red;
    border: $primary-red;


    &:hover {
      color: white;
      background: $primary-red;
      border: $primary-red;
    }
  }

  .check-submit {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .submitbtn {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .changePwdBody {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    padding: 20px;
    padding-left: 30px;
    border: 1px solid $secondary-grey;
    border-radius: 10px;

    .pwdUpdateBtn {
      width: 36.5%;
      display: flex;
      align-items: center;
      justify-content: right;

      button {
        margin-left: 10px;
      }

      .cancelBtn {
        background-image: linear-gradient(#e2dddd, #a5a5a5);
        border-radius: 5px;
      }

      .sendBtn {
        background-color: $secondary-blue;
        border-radius: 5px;
        color: $white;
        letter-spacing: 0.5px;
      }
    }

    // .pwdUpdateBtn {
    //   width: 36.5%;
    //   text-align: right;
    //   button {
    //     background-color: $secondary-blue;
    //     border-radius: 5px;
    //     color: $white;
    //   }
    // }
  }
}

.footer {
  justify-content: center;
  padding-top: 15%;
  // padding-bottom: 4%;
  display: flex;

  .footer-content {
    display: flex;
    align-items: center;
    // justify-items: center;
    // width: 60%;
    margin-left: auto;
    margin-right: auto;

    span {
      right: -13%;
      position: relative;
      font-size: 60%;
    }

    .footer-logo {
      justify-content: center;
      display: flex;
    }

    img {
      height: auto;
      max-width: 100px;
    }
  }

}

.signUpBtns {
  // width: 27%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: 15%;
  // gap: 20px;
}

.signup-button {
  margin: auto;
}

.remove-btn {
  position: absolute;
  left: 10px;
  // background-color: red;
  color: #569dfa;
  // border-radius: 50%;
  // width: 30px;
  // height: 30px;
  font-size: 18px;
  padding: 2px 0px;
  // z-index: 1000;
  top: 0px;
}

.ant-tooltip-inner {
  // background-color: rgba(0, 0, 0, 0.85) !important;
  border-radius: 6px !important;
}

.go-to-login-btn {
  padding: 5px 0px;
  width: 100%;
  margin-top: 10px;
  background: green;
  border: none;
  border-radius: 5px;
  color: white;
}

.signup-text {
  text-align: center;
  font-weight: bold;
  line-height: 30px;
}

@media (min-width:1024px) {
  .changePwdContainer {
    width: 60%;
  }
}

// }

.viewMicrositeModal {
  top: 20px !important;
}

.submitbtnend {
  margin-top: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.ant-col-10 {
  margin: 5px;
}

.ant-col-9 {
  font-weight: bold;
  margin: 5px;
}

.review-header {
  // border: 2px solid red;
  display: flex;
  justify-content: center;
}