.preview-secondary-player {
    padding-top: 10px;
}


.video-modal {
    width: 700px !important;

    iframe {
        height: 400px;
    }
}