@import "../../../assets/styles/main.scss";

.fixedRow {
  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.ant-popover-content {
  display: flex;
  justify-content: end;
  border: 1px solid $alphabet-btn-selected;
}

.popover-content {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: auto;

  .popover-single-content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    cursor: pointer;

    img {
      height: 23px;
      margin-right: 10px;
    }
  }

  .ant-divider {
    margin: 0px;
  }
}

.headerAv {
  img {
    background: none !important;

  }
}

.headerBannerRow {
  // background-image: url("../../../assets/images/c-header.svg");
  // background-color: #b5c8ff;
  background-color: white;
  border-bottom: 2px solid $border-grey;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  padding: 5px;
  max-height: 15vh;
  color: #222020;

  .headerBannerCol {
    padding-left: 1%;
    padding-right: 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 15vh;
  }
}

.org-admin-logo {
  height: 45px;
  width: 100px;
  object-fit: contain;
  border-radius: 10px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: none;
}

.headerBannerCol label {
  padding: 10px;
  // color: #fff;
}

.bannerTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  margin-top: 30px;
  // color: #fff;
}

// h3 {
//   color: $white;
// }

.profile-email {
  border-bottom: 1px solid #ccc;
  margin: 0px;
  font-size: 15px;
  font-weight: 500;
}

.bannerIcons {
  display: flex;
  text-align: center;

  div {
    background-color: $primary-green;
    padding: 10px;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;

    span {
      font-size: 20px;
    }
  }
}

.dropdown-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;

  a h3 {
    font-size: 16px;
  }

  div h3 {
    font-size: 16px;
  }

  div h3:hover {
    color: #334c98;
    cursor: pointer;
  }

  a h3:hover {
    color: #334c98;
  }
}

.header-logo {
  display: flex;
  width: fit-content;
  // height: 100%;

  .back-btn-header {
    padding-left: 0px;
    margin-left: 0px;
    margin-top: 3vh;
    margin-right: 10px;
    width: 20px;
  }

  .partner-logo {
    cursor: pointer;
    // width: 110px;
    // height: auto;
    height: 10vh;
    padding: 3% 0%;
    // margin-right: 10%;
  }

  .siteName-container {
    display: flex;
    // width: fit-content;
    align-items: center;
    // margin-left: 10%;
    overflow: visible;
    white-space: nowrap;

    label {
      font-size: 20px;
      font-weight: 400;
      // margin-left: 1%;
    }
  }
}

.admin-label {
  font-size: 1.5em;
  text-align: center;
  margin-left: 100px;
}

.headerBannerCol {
  .org-container {
    display: inline-flex;
    align-items: center;

    .org-text {
      .org-name {
        display: flex;
        padding-bottom: 0%;
        justify-content: end;
        font-size: 1rem;
      }

      .admin-label {
        font-size: 11px;
        padding-top: 0%;
        display: flex;
        justify-content: end;
      }
    }
  }
}

.ant-avatar {

  img {
    object-fit: contain !important;
  }
}