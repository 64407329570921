.not-found{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .head {
        font-size: 45px;
        font-weight: 800px;
    }
    .desc {
        font-size: 30px;
        width: 400px;
        text-align: center;
    }
    .co-link{
        background: rgb(6, 111, 163); 
        padding: 8px 15px;
        border-radius: 10px;
        a{
            color: white;
        }
    }
}