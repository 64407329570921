@import "../../../assets/styles/main.scss";

// .scrollable-tab-content {
//   max-height: 600px;
//   overflow-y: scroll;
//   overflow-x: hidden;
// }
// .scrollable-tab-content::-webkit-scrollbar {
//   width: 0;
// }

.mt15 {
  margin-top: 15px !important;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  /* -webkit-border-radius: 10px; */
  // border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(129, 128, 128, 0.8);
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(143, 142, 142, 0.4);
}

// .greenBtn {
//   background-color: $primary-green !important;
//   border: 1px solid $primary-green !important;
//   border-radius: 5px !important;
//   color: $white !important;
//   cursor: pointer;
// }

.headContainer {
  margin: 10px;
  padding: 10px;
}

.headContainer label {
  font-size: 22px;
}

.card {
  border: 1px solid darkgray;
  max-width: 450px;
  margin: 10px;
  padding: 2px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .cardContent {
    width: 100%;
    height: 120px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    .media {
      width: 70%;
      height: 100%;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    .youtube {
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 100%;

      .ytBtnOverlay {
        height: 70px;
        width: 70px;
        object-fit: contain;
        position: absolute;

        // top: 50%;
        // left: 15%;
        // transform: translate(-50%, -50%);
        // padding: 10px;
      }
    }

    .infoClass {
      width: 100%;
      margin-left: 10px;

      .logoClass {
      }
    }

    iframe {
      height: 120px;
    }
  }
}

.ant-tabs-nav-operations {
  display: none !important;
}

.ant-tabs-extra-content {
  height: 100%;
  background-color: white;
}

.leftArrow {
  // margin-right: 30px;
  // width: 5px;
  cursor: pointer;
  position: absolute;
  left: 0px;
  height: 110%;
  background-color: white;
  padding: 0px 5px;
  top: 0px;
  display: flex;
  align-items: center;
  // padding-top: 5px;
}

.rightArrow {
  cursor: pointer;
  padding: 0px 5px;
}

.ant-tabs-nav-list {
  padding-left: 0px;
  margin-left: 32px;
  margin-right: 32px;
}

.tabHeadContainer {
  padding: 20px 40px;
  position: relative;

  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > .ant-tabs-nav:before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav:before {
    bottom: unset;
    border: none;
  }

  .contactUsContent {
    .ant-tabs-content-holder {
      padding: 10px 0 !important;
    }
  }

  .ant-tabs-nav {
    &:nth-child(1) {
      .ant-tabs-tab-btn {
        color: $white;
      }
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    display: unset;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 1px solid $white !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $white;
    letter-spacing: 1px;
    // font-size: 20px;
  }

  .ant-tabs-tab-btn {
    color: #c0c4e9;
  }

  .homeIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;

    span {
      background-color: $primary-green;
      padding: 10px;
      border-radius: 5px;
      color: $white;
      font-size: 20px;
    }
  }

  .saveBtn {
    // position: absolute;
    // right: 40px;
    // background-color: $secondary-blue !important;
    // border: 1px solid $secondary-blue !important;
    z-index: 999;
    padding: 0 30px;
    font-size: 16px;
    text-align: center;
    border-radius: 5px !important;
  }

  .ant-tabs-tab {
    border-radius: 10px 10px 0px 0px !important;
  }

  .tabBodyContainer {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      // color: #d61f92 !important;
      color: $primary-red !important;
      letter-spacing: 1px;
      // font-size: 20px;
      font-weight: 600;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      // border-bottom: 2px solid #d61f92 !important;
      border-bottom: 2px solid $primary-red !important;
    }

    .ant-tabs-tab-btn {
      color: #000 !important;
      font-weight: 600;
      letter-spacing: 1px;
    }

    .ant-tabs-nav {
      margin: 0 !important;
    }

    .editBtn {
      background-color: $primary-orange;
      border: 1px solid $primary-orange;
      border-radius: 10px;
    }

    .ant-select-multiple .ant-select-selection-item {
      background-color: $secondary-blue;
      color: $white;
      border-radius: 5px;
    }

    .ant-tabs-content-holder {
      background-color: $white;
      padding: 20px;
      box-shadow: 0 0 3px #cccc !important;
      border-radius: 10px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;

      .ant-col-10 {
        max-width: 100%;
      }
    }

    .removeIcon {
      font-size: 25px;
      padding: 0px 0px 0px 10px;
      color: green;
    }

    .fieldRemoveIcon {
      position: absolute;
      display: inline-flex;
      justify-content: flex-end;
      font-size: 22px;
      padding: 0px 0px 0px 10px;
      color: green;
      top: 3px;
    }

    .clear-btn {
      background: rgb(212, 204, 204);
      border-radius: 5px;
      margin-right: 10px;
    }

    .custom-product {
      display: flex;
    }

    // .corousel {

    //   // margin-left: 25px;
    //   .ant-carousel {
    //     box-shadow: rgba(192, 192, 192, 0.5) 0px 5px 10px;
    //     border-radius: 20px;
    //   }
    // }

    .tabContent {
      .ant-picker {
        width: 100%;
      }

      .experienceImg {
        .ant-form-item-control-input-content {
          display: flex;
          align-items: center;
        }

        // span {
        //   margin-right: 25px;
        // }
      }
    }
  }
}

.videoOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

// .dropdow-configurations-btn {
//   background-color: green !important;
// }

.ytBtnOverlay {
  position: absolute;
  height: 20px;
}

.previewContainer {
  height: 88.5vh;

  h3 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    padding: 10px;
    margin: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .previewBody {
    // padding: 24px;
    border: 1px solid gainsboro;
    border-radius: 10px;
    height: 500px;
    overflow: auto;
    overflow-x: hidden;
    height: 84%;

    .carousel-main {
      padding: 0 20px 2px 25px;
    }

    .carousel-main-container {
      height: 200px;
      width: 97%;
      // margin: 20px;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }

    .corousel-container {
      height: 100%;

      .carousel-items {
        height: 100%;

        .slick-slider {
          height: 100%;

          .slick-prev:before,
          .slick-next:before {
            color: gray;
          }

          .slick-dots {
            margin-bottom: 5%;
          }

          .slick-list {
            height: 100% !important;

            .slick-track {
              .slick-active {
                align-items: center;
                // padding: 20px 0;
                // width: 200px;
              }

              .slick-center {
                transform: scale(1.08);
              }

              .slick-slide:not(.slick-active) {
                transform: scale(0.9); // margin: 20px 0;
                // content: "";
              }
            }
          }
        }
      }
    }

    .preview-container {
      align-items: center;
    }

    .sliderImg-alt {
      display: flex;
      align-items: center;

      .preview-iframe,
      .preview-img {
        height: 50px;
        width: 70px;
        border-radius: 8px;
        // height: auto;
        margin-left: auto;
      }
    }

    .sliderImg {
      display: flex;
      align-items: center;

      .preview-iframe,
      .preview-img {
        height: 50px;
        width: 70px;
        // height: auto;
        margin-left: auto;
      }

      .video-preview-image {
        height: 50px;
        width: 70px;
        border-radius: 8px;
        // height: auto;
        margin-left: auto;
        object-fit: contain;
      }
    }

    .ant-tabs-nav-operations {
      display: none !important;
    }

    .ant-tabs-content-holder {
      min-height: 100% !important;
    }

    .ant-tabs-extra-content {
      cursor: pointer;
      padding: 5px;
    }
  }

  .closeBtn {
    text-align: center;
    margin-top: 20px;
  }
}

.m-auto {
  margin: auto;
}

.forgot-btn {
  display: flex;
  justify-content: center;
}

.carousel-image {
  // height: 230px;
  // width: 100%;
  // border-radius: 10px;
}

.d-flex {
  display: flex;
}

.w-100 {
  min-width: 100%;
}

.custom-work {
  min-width: 100%;

  .ant-form-item-control-input {
    position: relative;
    background-color: #f5f5f5;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 5px !important;
    color: #d9d9d9;
  }

  .work-title {
    padding: 5px;
    color: #929292;
  }
}

.corousel-col {
  // max-height: 250px;
  // margin-left: 30px;
  flex: 0 0 37% !important;
  max-width: 37% !important;
  // width: 100%;
  // min-width: 90%;
}

.previewBody .slick-list {
  max-height: 200px;
  height: auto;

  .carousel-iframe,
  .carousel-img {
    width: 100%;
    margin: auto;
    height: 150px;
    object-fit: cover;
  }
}

.d-none {
  display: none;
}

.video-preview-image {
  width: 100%;
  height: 100%;
}

.collapsePanelWidth {
  width: 380px;
}

.rowBody {
  display: flex;

  .corousel-col {
    width: 100%;
    display: flex;
    justify-content: center;
    // align-items: center;
    // margin-left: auto;
  }
}

.data-loader {
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-loader-alt {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.youtube-in-tab {
  width: 100%;
  height: 230px;
}

.preview-tab-container {
  height: 100%;
  // overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center !important;

  .preview-item-container {
    width: 100%;
    display: flex;
    // align-items: center;
    // gap: 10px;
    // box-shadow: 0 0 1px rgba(184, 184, 184, 0.8);
    padding: 10px 20px;

    border: 0.5px solid #c5c5c5;
    // border: 1px solid lightgray;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 8px;
    margin: 4px;
    box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
    display: flex;
    align-items: center;
    justify-content: space-between;

    div:last-child {
      margin-left: auto;
    }
  }

  .preview-congtact-item {
    width: 100%;
  }

  .preview--contact-item-container {
    padding: 10px 20px;
    width: 100%;
    display: flex;
    // flex-direction: column;
    align-items: center;
    gap: 10px;

    div:last-child {
      margin-left: auto;
    }
  }

  .tabContent {
    font-weight: 600;
  }
}

.alert-msg {
  .ant-alert-content {
    display: flex;
    align-items: center;

    .ant-alert-message {
      margin-right: 10px;
      margin-bottom: 0px !important;
    }
  }

  .ant-alert-close-icon {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.tab-changes {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  padding: 20px;
}

.tabBodyContainer {
  .ant-tabs-content-holder {
    min-height: 80vh !important;
  }

  .ant-tabs-tab {
    font-weight: bold !important;
    border-radius: 8px 8px 0px 0px !important;
  }

  .ant-tabs-tab-active {
    background-color: #ffffff !important;
    border-bottom: 4px solid #3574f5 !important;
  }
}

.fileUploadBtn {
  background-color: green;
  color: white;
  padding: 5px;
  border-radius: 10px;
  margin-left: 10px;

  input {
    display: none !important;
  }
}

.preview-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 15px 0;
  gap: 20px;
}

.contact-tab-content-container {
  padding: 20px;
}

.card-item {
  border: 1px solid rgb(185, 185, 185);
  border-radius: 8px;
  padding: 16px;
  width: 99%;
  margin: 10px;
  display: flex;
}

.submit-section-bottom {
  width: 100%;
  // display: flex;
  padding-top: 30px;

  div {
    margin-left: auto;
    display: flex;
    justify-content: end;
  }

  .footer-container {
    justify-content: center;
    // padding-top: 1%;
    margin-bottom: -2%;
    display: flex;

    .footer-content {
      display: flex;
      align-items: center;
      // justify-items: center;
      // width: 60%;
      margin-left: auto;
      margin-right: auto;

      span {
        right: -13%;
        position: relative;
        font-size: 60%;
      }

      .footer-logo {
        justify-content: center;
        display: flex;
      }

      img {
        height: auto;
        width: 100px;
      }
    }
  }
}

.preview-secondary-player {
  padding: 20px 10px 5px 10px;
}

.preview-modal-root {
  top: 10px !important;
}

.vimeo-image {
  min-height: 150px !important;
  background-position: center;
  background-size: cover;
}

.iframe-comp {
  margin-left: auto;
  margin-right: auto;
}

.flexSwitch {
  display: flex;
  flex-direction: row;
}

.memberCondition {
  margin-left: 10px;
}

.background-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-heading-preview {
  display: flex;
  flex-direction: row;
}

// .member-form{
//   border: 2px solid red;

//   label{
//     border:2px solid blue;
//     max-width: 20px;
//     text-align: left;
//     padding: 0;
//     line-height: 1.3;
//   }
//   .member-form-switch{
//     border: 2px solid green;
//   }
// }

// .vimeo-play-button {
//   background-color: rgba(0, 0, 0, 0.8);
//   width: 5.6em;
//   height: 3.2em;
//   color: #fff;
//   position: absolute;
//   left: 26%;
//   top: 30%;

//   svg {
//     fill: white;
//     height: 32px;
//     margin-top: 4px;
//   }
// }

// .vimeo-embed {
//   position: fixed;
//   z-index: 1;
// }
