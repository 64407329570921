.files-feedback {
    // position: relative;
    display: flex;
    flex-direction: row;

    label {
        padding: 10px;
        padding-top: 0px;
        font-weight: bold !important;
        font-size: 17px !important;
    }

    .existing {
        // position: absolute;
        // right: 0px;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        // width: 30%;

        label {
            padding-left: 0px;
        }

        .fileList-Item {
            background-color: rgba(0, 0, 0, 0.05);
            // width: 250px;
            padding: 15px;
            height: 60px;
            border-radius: 10px;
            margin-top: 0px;
        }
    }

}

.doc{
    width: 100%;
}


.file-upload-feedback {
    // display: flex;
    // align-items: center;
    // width: 60%;

    label {
        padding: 10px;
        font-weight: bold !important;
        font-size: 17px !important;
    }

    // .dropbox {
    //     // width: 500px !important;
    //     margin: 20px;
    //     margin-left: 0px;
    // }

    .fileList-Item {
        background-color: rgba(0, 0, 0, 0.05);
        width: 250px;
        padding: 15px;
        border-radius: 10px;
    }

    .upload-component {


        .dropbox {
            width: 400px;
            // margin: 20px;
            // margin-left: 0px;
            // margin-top: ;
            margin: 0px;
            height: 60px;
            border-radius: 10px;
            padding: 15px;

            .ant-upload-btn {
                padding: 0px !important;
            }

            .box-content {
                display: flex;
                align-items: center;

                .ant-upload-drag-icon {
                    width: 60px;
                    margin: 0px !important;

                    svg {
                        height: 30px;
                        margin: 0px;
                    }
                }
            }
        }

        .fileList-Item {
            background-color: rgba(0, 0, 0, 0.05);
            width: 250px;
            padding: 15px;
            border-radius: 10px;
        }

    }
}

.form-save{
    .ant-form-item-row{
        float: right;
    }
}
