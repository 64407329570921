.primary-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  color: white;
  border: 0;
  background-color: #4b90f9 !important;
}
.pri-btn-loading,
.pri-btn-icon,
.pri-btn-label {
  margin-right: 5px;
}
.primary-btn:hover:not([disabled]) {
  background-color: #1b68f7 !important;
  color: white !important;
}
.primary-btn:active:not([disabled]) {
  background-color: #003cab !important;
  color: white !important;
}
.clear-btn {
  background: rgb(212, 204, 204) !important;
  border-radius: 5px;
  margin-right: 10px;
}

.btn-secondary { 
  background-color: #d1cfcf !important;
  color: black !important;
  cursor: pointer;
}



.btn-primary-alt { 
  background-color: #238a1f !important;
  color: white !important;
  cursor: pointer;
}
