@import "../../assets/styles/main.scss";

.ms-acc-main {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ms-accordian {
    display: flex;
    flex-direction: column;
}

.microsite-edit-header {
    width: 100%;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 6px #0000004D;
    border: 1px solid #BABABA;
    border-radius: 10px;
    padding: 0 30px;
    background-color: white;
    // margin-top: 15vh;
}

.microsite-header {
    width: 100%;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 6px #0000004D;
    border: 1px solid #BABABA;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    // background-color: red;
    // margin-top: 15vh;

    .microsite-header-first {
        display: flex;
        align-items: center;
        gap: 30px;

        .header-handler {
            img {
                width: 20px;
                height: 20px;
                // margin-top: 5px;
                margin-left: 10px;
                cursor: pointer;
            }
        }

        .microsite-header-first-title {
            font-size: 18px;
            font-weight: 600;

            .title-edit {

                margin-left: 10px;

                img {
                    width: 13px;
                    height: 20px;
                    margin-top: -2px;
                    cursor: pointer;

                }
            }
        }
    }

    .microsite-header-last {
        display: flex;
        flex-direction: column;
        border: none;
        overflow: hidden;
        margin-left: auto;

        .microsite-header-last-sub {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -5px 0 0;

            .switch-publish {
                // background-color: red;
                height: 38px;
            }

            .microsite-header-last-sub-sec {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 1;
                padding: 0px 10px;
                position: relative;
                cursor: pointer;

                img {
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                }
            }

            .microsite-header-last-sub-sec:after {
                content: '';
                height: 12px;
                width: 1px;

                position: absolute;
                right: 0;
                top: 12px;
                background: url(../../assets/images/path.svg) bottom right no-repeat;
            }

            .microsite-header-last-sub-sec-label {
                font-size: 10px;
                font-weight: 500;
                margin-top: 5px;
            }

        }
    }

}

.ms-accordian-content {
    width: 100%;
    box-shadow: 0px 3px 6px #0000004D;
    border: 1px solid #BABABA;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    min-height: 200px;
}

.ms-edit-main {
    width: 100%;
    display: flex;
    // box-shadow: 0px 3px 6px #0000004D;
    // border: 1px solid #BABABA;
    // border-radius: 10px;
    // padding: 10px 20px;
    // background-color: white;
    // min-height: 100px;
    align-items: center;

    .ms-edit-left {
        display: flex;
        align-items: center;
        gap: 50px;

        .ms-edit-media {
            display: flex;
            align-items: center;
            gap: 5px;

            .edit-img {
                height: 30px;
            }

        }

        .micrositename {
            margin-top: 25px;
            width: 400px;
            height: 40px;
        }
    }

    .ms-edit-right {
        margin-left: 14px;
        // margin-left: auto;
        display: flex;
        // gap: 10px;
    }
}


.org-admin-logo {
    height: 50px;
    width: 50px;
    object-fit: contain;
    border-radius: 10px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: none;
}


.ms-settings-header {
    width: 100%;
    font-weight: 700;
    text-align: center;
    font-size: 18px;
}


@media only screen and (max-width: 399px) {}

@media only screen and (min-width: 400px) and (max-width: 767px) {}

@media only screen and (min-width: 768px) {}



// @media only screen and (max-width: 399px) {}

// @media only screen and (min-width: 400px) and (max-width: 767px) {}

// @media only screen and (min-width: 768px) {}

@media (min-width:1440px) {
    .ms-accordian {
        // width: 90%;
        // margin-left: auto;
        // margin-right: auto;
    }
}

@media (min-width:2140px) {
    .ms-accordian {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
}

.modalBtns {
    display: flex;
    justify-content: end;
}

.confirmDenyBtn {
    background-color: red !important;
    border-radius: 2px;
    margin-left: 10px;
    color: white;

    &:hover {
        background-color: #ff0000a3 !important;
    }
}

.cancelBtn {
    background-color: white !important;
    border: 1px solid $primary-red !important;
    border-radius: 5px !important;
    color: $primary-red !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    font-weight: 600 !important;
    margin-right: 10px;
    cursor: pointer;
}

.footer {
    justify-content: center;
    padding-top: 3% !important;
    // padding-bottom: 4%;
    display: flex;

    .footer-content {
        display: flex;
        align-items: center;
        // justify-items: center;
        // width: 60%;
        margin-left: auto;
        margin-right: auto;

        span {
            right: -13%;
            position: relative;
            font-size: 60%;
        }

        .footer-logo {
            justify-content: center;
            display: flex;
        }

        img {
            height: auto;
            max-width: 100px;
        }
    }

}